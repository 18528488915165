import styled from 'styled-components';

export const IndustryLink = styled.button<{ isActive: boolean }>`
  /* Styles for the industry link */
  background-color: ${(props) => (props.isActive ? 'yellow' : 'transparent')};
  border: none;
  cursor: pointer;
  margin-right: ${props => props.theme.spacing.sm};
  padding: 1.5%;
  border-radius: 25px;
  font-size: ${props => props.theme.fonts.size.large};
  font-family: ${props => props.theme.fonts.primary};
  &:last-child {
    margin-right: 0;
  }

  &:focus {
    outline: none;
  }
`;

export const TickerContainer = styled.div`
  padding: ${props => props.theme.spacing.xs};
  width: fit-content;
  background-color: ${props => props.theme.colors.text};
  border-radius: ${props => props.theme.borderRadius.medium};
  font-weight: ${props => props.theme.fonts.weight.bold};
  font-size: ${props => props.theme.fonts.size.medium};
  color: ${props => props.theme.colors.background};
`;



export const SearchBarContainer = styled.div`
  display: flex;
  align-items: center;
  padding: ${props => props.theme.spacing.sm};
  background-color: ${props => props.theme.colors.white};
  border: ${props => props.theme.spacing.xxs} solid ${props => props.theme.colors.border};
  border-radius: ${props => props.theme.borderRadius.large};
  box-shadow: ${props => props.theme.shadows.low};
`;

export const SearchBarInput = styled.input`
  flex-grow: 1;
  border: none;
  padding: 10px 3px;
  font-size: 16px; // Assuming a default size, adjust as necessary
  color: #000000;
  background-color: transparent;
  max-width: 95%;
  margin-left: 1%;
  &::placeholder {
    color: #A9A9A9;
  }

  &:focus {
    outline: none;
  }
`;

export const PercentChangeContain = styled.div`
  padding: ${props => props.theme.spacing.sm};
  width: fit-content;
  border: ${props => props.theme.spacing.xxxs} solid ${props => props.theme.colors.border};
  background: ${props => props.theme.colors.secondary};
  border-radius: ${props => props.theme.borderRadius.medium};
  font-weight: ${props => props.theme.fonts.weight.bold};
  font-size: ${props => props.theme.fonts.size.medium};
  margin: 2% 0 0 0;
  color: ${props => props.theme.colors.black};
`;

export const NegativeChange = styled.div`
  display: flex;
  padding: ${props => props.theme.spacing.sm};
  width: fit-content;
  border: ${props => props.theme.spacing.xxxs} solid ${props => props.theme.colors.border};
  background: rgba(233, 41, 98, 0.10);
  border-radius: ${props => props.theme.borderRadius.medium};
  font-weight: ${props => props.theme.fonts.weight.bold};
  font-size: ${props => props.theme.fonts.size.medium};
  color: ${props => props.theme.colors.black};
`;

export const PostiveChange = styled.div`
  padding: ${props => props.theme.spacing.sm};
  width: fit-content;
  border: ${props => props.theme.spacing.xxxs} solid ${props => props.theme.colors.border};
  background: ${props => props.theme.colors.primary};
  border-radius: ${props => props.theme.borderRadius.medium};
  font-weight: ${props => props.theme.fonts.weight.bold};
  font-size: ${props => props.theme.fonts.size.medium};
  color: ${props => props.theme.colors.black};
`;

export const CarouselItem = styled.div`
  padding: 10px;
  margin: ;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 150px;
  height: 125px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${props => props.theme.colors.primary};
`;

export const StandardItemContainer = styled.div`
  padding: ${props => props.theme.spacing.sm};
  border: ${props => props.theme.spacing.xxxs} solid ${props => props.theme.colors.border};
  border-radius: ${props => props.theme.borderRadius.medium};
  height: auto;
  background-color: ${props => props.theme.colors.primary};
`;
