import React, { useState } from 'react';
import LogoImg from './images/figures/new-logo.png';
import { SideMenu } from './components/side-menu';
import { AlignHorizon, ContainIcon, SmallBox } from './styles/basic';



function Header() {
  const [isMenuOpen, setMenuOpen] = useState(false);


  return (
    <>
      <AlignHorizon as="nav" style={{justifyContent:'space-between'}}>
        {/*<ContainIcon src={SearchIcon} data-tip data-for="logoTooltip" />*/}
        <SmallBox />
        <a href='https://www.lowkeylavish.com' style={{textDecoration: 'none', color: 'black'}}> <ContainIcon src={LogoImg} style={{width: '50px', height: '50px'}}/> </a>
        <SmallBox />
        {/*<AlignHorizon style={{ display: 'flex', gap: '2rem' }}>
          <ContainIcon src={HamburgerIcon} onClick={toggleMenu} />
        </AlignHorizon>*/}
      </AlignHorizon>
      <SideMenu isOpen={isMenuOpen} onClose={() => setMenuOpen(false)} />
      <SmallBox />
    </>
  );
}

export default Header;


