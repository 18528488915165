import React from 'react';
import { lightTheme, darkTheme } from './themes'; 
import { ThemeProvider } from 'styled-components';
import { AppBackground, MediaScreenMobile } from './constants/styles/basic';
import Header from './constants/header';
import Footer from './constants/footer';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'; // Import Navigate
import MainSearchPage from './pages/content-search';
import ArticlePage from './pages/view-article';

function App() {
  const currentTheme = 'light';
  const theme = currentTheme === 'light' ? lightTheme : darkTheme;

  return (
    <ThemeProvider theme={theme}>
      <MediaScreenMobile>
        <AppBackground>
          <Router>
            <Header />
            <Routes>
              <Route path="/articles" element={<MainSearchPage />} />
              <Route path="/articles/:id" element={<ArticlePage />} />
              {/* Add a catch-all route to redirect undefined routes to /articles */}
              <Route path="*" element={<Navigate to="/articles" />} />
            </Routes>
            <Footer />
          </Router>
        </AppBackground>
      </MediaScreenMobile>
    </ThemeProvider>
  );
}

export default App;
