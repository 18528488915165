import React from 'react';
import styled from 'styled-components';
//import { StyledPresoText } from '../../landing-components/in-style';
import { ReactComponent as CloseIconSvg } from '../../logo.svg';
import { Link } from 'react-router-dom';




const StyledCloseIcon = styled(CloseIconSvg)`
  fill: #ffffff; /* Red color */
  height: 2rem;
  width: 100%;

`;

const SideMenuContainer = styled.div<{ isOpen: boolean }>`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: Space-between;

  gap: 2.5%;
  top: 0;
  right: 0;
  width: 18vw;
  height: 100%;
  background-color: #ffffff;
  border-left: solid 2px black; // Adjust the color based on your theme or design
  transform: ${({ isOpen }) => (isOpen ? 'translateX(0)' : 'translateX(100%)')};
  transition: transform 0.3s ease-in-out;
`;

const CloseButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
`;

interface SideMenuProps {
  isOpen: boolean;
  onClose: () => void;
}



const Dropdown = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  
`;

const DropdownContent = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  align-items: flex-start;
  gap: .5rem;
  z-index: 1;
  padding: 5%;

  ${Dropdown}:hover & {
    display: flex;
  }
`;

const MenuItem = styled.div`
  font-size: 1.5rem;
  cursor: pointer;
  text-decoration: none;
  padding: 3%;
  color: black;
  &:hover {
    background-color: #9AD7F3;
    color: black;
  }
`;

// Define the type for menu item data
interface MenuDataItem {
  title: string;
  url: string;
  subItems?: MenuDataItem[];
}

// The list of menu items
const menuData: MenuDataItem[] = [
    {
        title: 'Home',
        url: '/',
        subItems: [
          { title: 'Interactive Courses', url: '/' },
          { title: 'Personalize Partna', url: '/' },
          { title: 'Be an Ambassador', url: '/' },
        ],
      },
  {
    title: 'Knowledge',
    url: '/knowledge',
    subItems: [
      { title: 'Good Morning Partna', url: '/knowledge' },
      { title: 'Trending Topics', url: '/knowledge/trending-topics' },
      { title: 'Top Stories', url: '/knowledge/top-stories' },
    ],
  },
  {
    title: 'Company',
    url: '/',
    subItems: [
      { title: 'About Us', url: '/' },
      { title: 'Our Mission', url: '/' },
      { title: 'Get in Touch', url: '/' },
    ],
  },
  {
    title: 'Value Drivers',
    url: '/',
    subItems: [
      { title: 'Self-Sustaining', url: '/' },
      { title: 'AI Co-Pilot', url: '/' },
      { title: 'Affordable', url: '/' },
    ],
  },
  // ... other items
];

export const SideMenu: React.FC<SideMenuProps> = ({ isOpen, onClose }) => {
  const renderMenuItems = (items: MenuDataItem[]) => {
    return items.map((item, index) => (
      <Dropdown key={index}>
        <MenuItem as={Link} to={item.url} style={{fontSize: '1.125rem', borderBottom: 'solid 2px black'}}>
          {item.title}
        </MenuItem>
        {item.subItems && (
          <DropdownContent>
            {item.subItems.map((subItem, subIndex) => (
              <MenuItem as={Link} to={subItem.url} key={subIndex} style={{fontSize: '1.0125rem', textAlign: 'left', fontWeight: '600'}}>
                {subItem.title}
              </MenuItem>
            ))}
          </DropdownContent>
        )}
      </Dropdown>
    ));
  };
  

  return (
    <SideMenuContainer isOpen={isOpen}>
      <div>
      <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: '1.25% .5% 0 0'}}>
      <CloseButton onClick={onClose}>
      <StyledCloseIcon />
      </CloseButton>
      </div>
      {renderMenuItems(menuData)}
      </div>
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 5%', color: 'black'}}> <p> Designed by Partna inc.</p></div>
    </SideMenuContainer>
  );
};