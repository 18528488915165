import React from 'react'; // Ensure React is imported if using JSX
import NewsList from '../contentSearch-components/rec-list';
import { AlignVertical } from '../constants/styles/basic';
import { StandardHeaderText } from '../constants/styles/text';



//        <NewsList data={data?.items} />


function MainSearchPage() {
  // Assuming newsdata should be data.items based on your API structure
  
  
  return (
    <>
    <AlignVertical>
    <StandardHeaderText style={{ fontSize: '60px', color: '#008080'}}>Quiet Luxury         <span style={{fontSize: '60px', color: '#000'}}>Daily</span></StandardHeaderText>
    </AlignVertical>
      <div style={{ margin: '10% 20%' }}>
        <NewsList />
      </div>
    </>
  );
}

export default MainSearchPage;
