import React, { useEffect, useState } from 'react';
import { AlignHorizon, AlignVertical, ListItemLink, SquareImage} from '../constants/styles/basic';
import Default from '../constants/images/default.png';
import { StandardParagraph, StandardSubHeaderText  } from '../constants/styles/text';
import { NewsItemDetail, NewsSummaryData } from './news-models';
import "@fontsource/libre-bodoni"; // Defaults to weight 400
import "@fontsource/libre-bodoni/600.css"; // Specify weight
import "@fontsource/libre-bodoni/600-italic.css";
import { TickerContainer } from '../constants/styles/specific';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const Venture = styled(AlignVertical)`
  margin: auto, 
  alignItems: flex-start, 
  max-width: 85%,

  @media (max-width: 768px) {
    max-width: 100%;
    margin: 0;
    text-align: center;
  }

`;

const Henture = styled(AlignHorizon)`
  align-items: flex-end,
  gap: .1em

  @media (max-width: 768px) {
    justify-content: flex-end;
  }

`;

const NoMargText = styled(StandardParagraph)`
@media (max-width: 768px) {
  max-width: 100%;
  margin: 0;
}
`;
  


function NewsList() {
  const [newsItems, setNewsItems] = useState<NewsItemDetail[]>([]);

  

  useEffect(() => {
    fetch('https://create.lowkeylavish.com/api/v2/pages/')
      .then(response => response.json())
      .then((data: NewsSummaryData) => {
        const fetchDetailsPromises = data.items.map(item => {
          // Replace 'localhost:8000' with 'create.lowkeylavish.com' in the URL
          const detailUrl = item.meta.detail_url.replace('localhost:8000', 'create.lowkeylavish.com');
          // Make sure the detail URL uses HTTPS
          const secureDetailUrl = detailUrl.replace('http://', 'https://');
          
          return fetch(secureDetailUrl)
            .then(response => response.json());
        });
        return Promise.all(fetchDetailsPromises);
      })
      .then((details: NewsItemDetail[]) => {
        setNewsItems(details);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);
  


  function isValidUrl(string: string) {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false; // If the URL is not valid, return false
    }
  }

  return (
    <>
    <AlignVertical style={{gap: '1em'}}>
      {newsItems.slice(1).map(item => (
        <motion.div
        initial={{ opacity: 0, y: 20 }} // Initial animation state (hidden)
        animate={{ opacity: 1, y: 0 }} // Animate to visible and original position
        transition={{ duration: 0.5 }} // Animation duration
      >
        <AlignVertical key={item.id} style={{margin: 'auto', alignItems: 'flex-start', borderBottom: 'solid 1px #B9A476', paddingBottom: '1rem', gap: '1em', width: '100%'}}>
          
          <AlignHorizon style={{justifyContent: 'space-between', gap: '2rem'}}>

          <AlignHorizon>
              {isValidUrl(item.cover_image_description) 
                ? <SquareImage src={item.cover_image_description} alt="Cover image" />
                : <SquareImage src={Default} alt="Default image" />
              }
            </AlignHorizon>


            <Venture>
              <AlignVertical style={{justifyContent: 'space-between', alignItems: 'flex-start', gap: '.1rem', paddingBottom: '.3em'}}>
              <TickerContainer>{item.article_categorical_topic || 'Quiet Luxury'}</TickerContainer>
              
              </AlignVertical>
              <ListItemLink to={`/articles/${item.id}`}>
                <StandardSubHeaderText style={{ fontFamily: 'Libre Bodoni, serif' }}>{item.article_title}</StandardSubHeaderText>
              </ListItemLink>
              
              
      
              
            </Venture>

            

        </AlignHorizon>
        <StandardParagraph style={{margin: 0}}>{item.meta_description}</StandardParagraph>
        <Henture>
        <StandardParagraph>Published on {new Date(item.meta.first_published_at).toLocaleDateString()}</StandardParagraph>
        <NoMargText> @ {new Date(item.meta.first_published_at).toLocaleTimeString()}</NoMargText>
        </Henture>

        </AlignVertical>
        </motion.div>
      ))}
    </AlignVertical>
    </>
  );
}

export default NewsList;
     
