import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { StandardHeaderText, StandardParagraph, StandardSmallText, StandardSubHeaderText } from '../constants/styles/text';
import { AlignHorizon, AlignVertical, ArticleImage } from '../constants/styles/basic';
import { TickerContainer } from '../constants/styles/specific';
import '@fontsource/eb-garamond/400.css'; // Use the appropriate weight as needed
import "@fontsource/libre-bodoni"; // Defaults to weight 400
import "@fontsource/libre-bodoni/600.css"; // Specify weight
import "@fontsource/libre-bodoni/600-italic.css";
type ArticleData = {
  title: string;
  article_categorical_topic?: string;
  article_title?: string;
  article_subtitle?: string;
  meta_description?: string;
  seo_keywords?: string;
  cover_image_description?: string;
  opening_paragraph: string;
  body: Array<{
    type: string;
    value: string;
    id: string;
  }>;
};

function ArticlePage() {
  const { id } = useParams<{ id: string }>();
  const [articleData, setArticleData] = useState<ArticleData | null>(null);

  useEffect(() => {
    fetch(`https://create.lowkeylavish.com/api/v2/pages/${id}/`)
      .then(response => response.json())
      .then(data => {
        setArticleData(data as ArticleData);
      })
      .catch(error => {
        console.error('Error fetching article data:', error);
      });
  }, [id]);

  if (!articleData) {
    return <div>Loading...</div>;
  }

  function removePTagAndDataBlockKey(htmlString: string): string {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const elements = doc.querySelectorAll('p[data-block-key]');

    elements.forEach(element => {
        const parent = element.parentNode;
        while (element.firstChild) {
            parent?.insertBefore(element.firstChild, element);
        }
        parent?.removeChild(element);
    });

    return doc.body.innerHTML;
}




  return (
    <AlignVertical as="article">
    <AlignVertical style={{maxWidth: '60vw', gap: '1em', alignItems: 'flex-start'}}>
      <AlignHorizon style={{justifyContent: 'flex-start', margin: 'auto 0'}}>
      <TickerContainer>{articleData.article_categorical_topic ?? 'Quiet Luxury'}</TickerContainer>        
      </AlignHorizon>
      <StandardHeaderText style={{ fontFamily: 'Libre Bodoni, serif', margin: 'auto 0' }}>{articleData.article_title}</StandardHeaderText>
      <StandardSubHeaderText style={{ fontFamily: 'Libre Bodoni, serif', margin: 'auto 0' }}>{articleData.article_subtitle}</StandardSubHeaderText>
      <StandardParagraph style={{margin: 'auto 0'}}>{articleData.cover_image_description}</StandardParagraph>
      <StandardParagraph style={{margin: 'auto 0'}}>{removePTagAndDataBlockKey(articleData.opening_paragraph)}</StandardParagraph>
      {articleData.body && Array.isArray(articleData.body) && (
        articleData.body.map((item, index) => {
          switch (item.type) {
            case 'section_heading':
              return <StandardSubHeaderText as='h3' key={item.id} style={{ fontFamily: 'Libre Bodoni, serif', margin: 'auto 0' }}>{removePTagAndDataBlockKey(item.value)}</StandardSubHeaderText>;
            case 'paragraph':
              return <StandardParagraph key={item.id} style={{margin: 'auto 0'}}>{removePTagAndDataBlockKey(item.value)}</StandardParagraph>;
            case 'image_description':
              // Assuming you want to render the image URL as an image
              return <>
                <AlignVertical as='section' style={{margin: 'auto', gap: '0em'}}>
                <figure style={{margin: '0', padding: '0'}}>
                <ArticleImage  style={{gap: '0em'}} key={item.id} src={item.value} alt="" />
                </figure>
                <StandardSmallText as='figcaption' style={{gap: '0em', width: 'fit-content', textAlign: 'center'}}>Photo was taken from { item?.value}</StandardSmallText>
                </AlignVertical>
              </>;
            default:
              return null;
          }
        })
      )}
    </AlignVertical>
    </AlignVertical>
  );
}

export default ArticlePage;
