import React from 'react';
import LogoImg from './images/figures/new-logo.png';
import { AlignHorizon, AlignVertical, ContainIcon, SmallBox } from './styles/basic';





const Footer = () => {
  return (
    <>
    <AlignVertical style={{paddingTop: '15px'}}>
    <a href='https://www.lowkeylavish.com' style={{textDecoration: 'none', color: 'black'}}> <ContainIcon src={LogoImg} style={{width: '50px', height: '50px'}}/> </a>
      <SmallBox />
      
      <AlignHorizon />
    </AlignVertical>
    </>
  );
};

export default Footer;
