import styled from "styled-components";

export const BoldHeading = styled.h2`
  font-weight: 500;
  font-family: ${props => props.theme.fonts.primary};
`;

export const StandardParagraph = styled.p`
  margin: auto;
  font-size: ${props => props.theme.fonts.size.paragraph};
  color: ${props => props.theme.colors.text};
  font-family: ${props => props.theme.fonts.primary};
  &::-webkit-scrollbar {
    width: ${props => props.theme.scrollbar.width};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${props => props.theme.scrollbar.thumbColor};
    &:hover {
      background-color: ${props => props.theme.scrollbar.hoverThumbColor};
    }
  }

  &::-webkit-scrollbar-track {
    background-color: ${props => props.theme.scrollbar.trackColor};
  }

  @media (max-width: 768px) {
    text-align: center;
  }


`;

export const StandardSmallText = styled.p`
  margin: auto;
  font-size: ${props => props.theme.fonts.size.small};
  color: ${props => props.theme.colors.text};
  font-family: ${props => props.theme.fonts.primary};
`;

export const StandardMediumText = styled.p`
  margin: auto;
  font-size: ${props => props.theme.fonts.size.medium};
  color: ${props => props.theme.colors.text};
  font-family: ${props => props.theme.fonts.primary};
`;

export const StandardHeaderText = styled.h1`
  margin: auto;
  font-size: ${props => props.theme.fonts.size.section_heading};
  color: ${props => props.theme.colors.text};
  font-family: ${props => props.theme.fonts.primary};

  @media (max-width: 768px) {
    text-align: center;
  }
`;

export const StandardSubHeaderText = styled.h2`
  margin: auto;
  font-size: ${props => props.theme.fonts.size.section_heading};
  color: ${props => props.theme.colors.highlight};
  font-family: ${props => props.theme.fonts.primary};
  text-align: left;

  @media (max-width: 768px) {
    text-align: center;
  }
`;